<template>
  <div>
    <WeWidget
      cards="1"
      choropleth="1"
      cart-average="1"
      product="1"
      category-product="1"
    />
    <WeFailedOrderBadge />
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
